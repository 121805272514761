import React from "react";

import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Container from "../components/Container";
import { Typography } from "@ahazou/react-components";
import "./style.css";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Ahazou! Política de Privacidade" />

      <Layout>
        <Container className="privacy-page__content">
          <Typography element="h1" variant="displayXL">
            Política de Privacidade
          </Typography>

          <Typography element="h2" variant="bodyM">
            Atualizada em 17 de junho de 2020. <br />
            <br />
          </Typography>

          <Typography element="h3" variant="displayM">
            Informações ao usuário
          </Typography>

          <Typography variant="bodyS">
            Para que possamos oferecer um aplicativo melhor e mais direcionado
            às suas preferências, processar eventuais pedidos e reclamações,
            informá-lo sobre alterações relevantes em nossos serviços,
            compreender suas preferências nas escolhas efetuadas por meio do
            aplicativo, sugerir conteúdo de acordo com seus critérios, de forma
            a tornar sua busca mais próxima dos seus interesses, é importante
            contarmos com suas informações, por isso, leia atentamente essa
            política e nossos <a href="/terms-of-use">Termos de Uso.</a>
          </Typography>

          <Typography element="h3" variant="displayM">
            O que esta política de privacidade abrange?
          </Typography>

          <Typography variant="bodyS">
            Bem-vindo/a ao Ahazou App, o aplicativo mobile em serviço de
            Carreira Beauty Portal de Beleza Ltda. (“Ahazou”, “nós”,
            “aplicativo” ou “App”). Nossa política de privacidade explica como
            nós coletamos, usamos, divulgamos, e protegemos as suas informações
            que se aplicam ao nosso serviço e suas opções sobre a coleta e o uso
            de suas informações. Se você não concorda com esta Política de
            Privacidade em termos gerais ou em parte dela, não deve usar os
            nossos serviços e/ou aplicativo. Se você optou em continuar,
            apresentaremos a seguir a Política de Privacidade do aplicativo
            Ahazou.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: esta política define como o Ahazou coleta e usa as
            informações sobre você quando você usa os serviços do aplicativo
            Ahazou. Esta política também explica as escolhas que você pode fazer
            sobre a maneira como usamos suas informações.
          </Typography>

          <Typography element="h3" variant="displayM">
            1. Informações que coletamos e como as usamos
          </Typography>

          <Typography variant="bodyS">
            Nós coletamos as seguintes informações sobre você:
          </Typography>

          <Typography element="h4" variant="displayS">
            (a) Informação que você nos fornece diretamente
          </Typography>

          <Typography variant="bodyS">
            Nós podemos solicitar algumas informações como o seu nome ou
            apelido, localização (adquirida por geolocalização), e-mail, número
            de WhatsApp e temas que gostaria de publicar, quando você se
            registrar para utilizar os serviços do App Ahazou. Nós também
            coletamos as mensagens que você nos envia via aplicativo (como
            exemplo Ideias ou Chat), e podemos coletar as informações que você
            incluir nas postagens dentro do Ahazou (como conteúdo em imagem,
            logo e texto). Nós usamos estas informações para operacionalizar,
            manter, e fornecer recursos e funcionalidades do aplicativo para
            você, nos comunicarmos com você e endereçar qualquer problema que
            você reporte em relação aos nossos serviços.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: se você nos passar suas informações, nós vamos mantê-las
            e usá-las para fornecer um melhor serviço para o seu uso do
            aplicativo
          </Typography>

          <Typography element="h4" variant="displayS">
            (b) Informações que recebemos de terceiros (third party data)
          </Typography>

          <Typography variant="bodyS">
            Nós podemos receber informações a seu respeito através de terceiros.
            Como exemplo, se você acessar os nossos canais digitais (sites,
            redes sociais, Blog etc.) ou se cadastrar no aplicativo através de
            uma conta pré existente, como exemplo a sua conta do Facebook ou
            Google (“entrar com Facebook”), este terceiro pode nos passar
            algumas informações referente ao seu uso da plataforma em questão.
            Esta informação pode incluir, mas não se limitar a, o seu “user ID”
            associado à sua conta (como exemplo, o seu user ID do Facebook), o
            “token” de acesso da sua conta, e qualquer informação que você
            permitiu que este terceiro compartilhe com o Ahazou ou que você
            disponibilizou publicamente na plataforma. Você deve sempre revisar,
            e se necessário, atualizar suas configurações de privacidade em
            sites e plataformas terceiras, antes de se conectar ao Ahazou
            através deles. Caso você não utilize dados de uma plataforma
            terceira para se conectar ao Ahazou, nós não receberemos e não
            teremos acesso a informações destes serviços.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: quando você usa os nossos serviços, terceiros podem nos
            fornecer algumas informações sobre você, especialmente se você se
            cadastrar (fazer login) utilizando a conta de uma plataforma
            terceira. Quando você escolher entrar através de sua conta do
            Facebook ou Google, por exemplo, estas plataformas nos fornecerão
            algumas informações sobre o seu perfil.
          </Typography>

          <Typography element="h4" variant="displayS">
            (c) Suas informações que coletamos automaticamente
          </Typography>

          <Typography variant="bodyS">
            Nós vamos coletar diretamente dados para análise, ou utilizar
            ferramentas de análise de terceiros, que nos ajudará metrificar
            tráfico e tendências de uso do nosso aplicativo. Estas ferramentas
            coletam informação enviada pelo seu aparelho móvel, incluindo
            páginas/telas visitadas, posts acessados e outras informações, que
            nos ajudarão a melhorar os nossos serviços e a sua experiência de
            uso do aplicativo.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: para nos ajudar a melhorar os serviços que prestamos a
            você, nós coletamos informações de como você usa o nosso aplicativo.
          </Typography>

          <Typography element="h4" variant="displayS">
            (d) Informações de Login (acesso ao aplicativo)
          </Typography>

          <Typography variant="bodyS">
            As informações de Login, necessárias para acessar o App são
            automaticamente reportadas pelo o seu aparelho móvel toda vez que
            você acessa o aplicativo. Quando você usa o Ahazou, os nossos
            servidores automaticamente recordam algumas informações de login, e
            podem nos fornecer informações como o sistema operacional do
            aparelho celular utilizado, versão do aplicativo em uso, telas
            acessadas, entre outras.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: toda vez que você acessa o Ahazou, o seu aparelho móvel
            nos envia informações sobre o seu uso e interações com o App. Estas
            informações ficam armazenadas em nossos servidores.
          </Typography>

          <Typography element="h4" variant="displayS">
            (e) Identificação de dispositivo e sistema operacional do aparelho
            móvel
          </Typography>

          <Typography variant="bodyS">
            Quando você utiliza o Ahazou através de um aparelho móvel (incluindo
            mas não limitando a Smart phones ou tabletes), nós podemos coletar,
            monitorar e armazenar a informação do tipo de dispositivo e qual o
            sistema operacional dele (ex: IOS ou Android). <br />O dado de
            identificador de dispositivo e de sistema operacional, pode te
            ajudar a fazer login mais rapidamente e aprimorar sua navegação pelo
            App. Alguns recursos do aplicativo podem não funcionar corretamente
            se o uso ou a disponibilidade de identificadores de dispositivo
            estiverem prejudicados ou desabilitados. Os identificadores de
            dispositivo usados pelo Ahazou incluem o Android Advertising ID e o
            iOS Advertising Identifier.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: para rastrear os diferentes dispositivos e pessoas que
            estão usando nosso App, podemos armazenar dados sobre o seu
            dispositivo móvel que nos ajudarão a deixar o serviço mais eficaz.
          </Typography>

          <Typography element="h4" variant="displayS">
            (f) Dados de Localização
          </Typography>

          <Typography variant="bodyS">
            Você pode nos dar permissão de acessar a sua informação de
            localização quando se cadastra no Ahazou. Os dados de localização
            nos transmitem informações sobre como você navega e usa o App e nos
            permitem oferecer conteúdo regionalizado e localizado.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: nós usaremos os seus dados de localização para te
            oferecer um serviço localizado e personalizado.
          </Typography>

          <Typography element="h3" variant="displayM">
            2. Como nós usamos as suas informações
          </Typography>

          <Typography variant="bodyS">
            Nós usamos as informações que coletamos do seu usuário para os fins
            já mencionados anteriormente e que serão detalhados a seguir:
          </Typography>

          <Typography variant="bodyS">
            Usamos as informações que você nos cedeu para fornecer o serviço do
            Ahazou a você. Isso inclui permitir que você efetue o login no
            Ahazou, operando e mantendo o uso, fornecendo acesso aos seus posts
            e edições, e cobrando pela utilização ilimitada do serviço, caso
            você tenha optado por isso. Também usamos as informações que
            coletamos sobre você automaticamente para te lembrar e você não
            precisar digitá-las novamente durante o uso ou na próxima vez que
            acessar o aplicativo.
          </Typography>

          <ul>
            <Typography element="li" variant="bodyS">
              Para análise de dados: usamos as suas informações para nos ajudar
              a melhorar o serviço do Ahazou e a experiência de nossos usuários,
              inclusive monitorando métricas, como número total de acessos,
              tráfego, compartilhamento e padrões de uso.
            </Typography>

            <Typography element="li" variant="bodyS">
              Personalizando o Ahazou para você: usamos as informações que você
              nos fornece e as coletadas sobre você para garantir que o seu uso
              do App seja personalizado e de acordo com as suas necessidades.
              Por exemplo, se você nos informar os seus temas de publicação,
              vamos recomendar conteúdo das áreas escolhidas que provavelmente
              sejam relevantes para você.
            </Typography>

            <Typography element="li" variant="bodyS">
              Para nos comunicarmos com você sobre o aplicativo: usamos suas
              informações de contato para te acessar e enviar comunicações sobre
              elementos críticos, oportunidades, campanhas e promoções. Por
              exemplo, podemos enviar e-mails sobre problemas técnicos, alertas
              de segurança ou uma promoção que te dará acesso exclusivo ao
              conteúdo.
            </Typography>

            <Typography element="li" variant="bodyS">
              Para promover e impulsionar o envolvimento com o aplicativo
              Ahazou: usamos suas informações de contato para te acessar e falar
              sobre os recursos e ofertas relacionados ao App em que acreditamos
              que você estaria interessado/a. Também usamos as informações que
              coletamos sobre você para garantir que você obtenha as ofertas e
              promoções mais relevantes com base no uso do serviço e em suas
              preferências. Você pode optar por não receber essas comunicações,
              conforme mais pra frente.
            </Typography>

            <Typography element="li" variant="bodyS">
              Sucesso do cliente: usamos informações sobre você, informações
              fornecidas à nossa equipe de sucesso do cliente e informações
              sobre suas interações com o App para resolver problemas técnicos
              que você pode ter enfrentado e para garantir que possamos reparar
              e melhorar os nossos serviços para todos os usuários do Ahazou.
            </Typography>

            <Typography element="li" variant="bodyS">
              Para medidas de segurança: usamos informações sobre você para
              monitorar atividades que julgamos suspeitas ou potencialmente
              fraudulentas e para identificar violações a esta Política de
              Privacidade ou dos nossos{" "}
              <a href="/termos-of-use">Termos de Uso</a>.
            </Typography>

            <Typography element="li" variant="bodyS">
              Para informações que você concordou em ceder: de tempos em tempos,
              o Ahazou pode solicitar o seu consentimento para usar suas
              informações para um fim específico. Quando você consentir em
              fazê-lo, usaremos para o fim mencionado. Quando você não desejar
              mais que utilizemos suas informações para tal fim, você poderá
              retirar seu consentimento.
            </Typography>

            <Typography element="li" variant="bodyS">
              Para assuntos nos quais somos obrigados a usar suas informações
              por lei: o Ahazou usará ou divulgará suas informações sempre que
              acreditarmos que tal ação é necessária para (a) cumprir a lei e os
              pedidos de aplicação da lei; (b) fazer cumprir nossos{" "}
              <a href="/termos-of-use">Termos de Uso</a> ou proteger a segurança
              ou integridade do nosso serviço; e / ou (c) exercer ou proteger os
              direitos, propriedade ou segurança pessoal do Ahazou, de nossos
              usuários ou de outros.
            </Typography>
          </ul>

          <Typography variant="bodyS">
            Em resumo: estas são as razões do porquê o Ahazou precisa de
            informações ao seu respeito.
          </Typography>

          <Typography element="h3" variant="displayM">
            3. Compartilhando as suas informações
          </Typography>

          <Typography variant="bodyS">
            Não vamos ceder ou vender suas informações a terceiros fora do
            Ahazou ou de nossas empresas do grupo (incluindo qualquer
            controladora, subsidiárias e afiliadas) sem o seu consentimento,
            exceto conforme indicado nesta seção:
          </Typography>

          <Typography variant="bodyS">
            Em resumo: nós não compartilhamos as suas informações pessoais sem a
            sua permissão, com exceção dos casos a seguir:
          </Typography>

          <Typography element="h4" variant="displayS">
            (a) Com que nós compartilhamos informação
          </Typography>

          <Typography variant="bodyS">
            Compartilhamos suas informações com provedores de serviços
            terceirizados com a finalidade de fornecer os nossos serviços para
            você e facilitar os interesses legítimos do Ahazou em fornecer um
            serviço útil. Esses prestadores de serviços somente terão acesso às
            suas informações quando for necessário no caso de o Ahazou ter
            contratado o provedor de serviços. Exigiremos que esses terceiros
            cumpram esta Política de Privacidade, termos de processamento de
            dados apropriados e quaisquer leis aplicáveis.
          </Typography>

          <Typography variant="bodyS">
            Alguns dos terceiros com os quais o Ahazou pode compartilhar suas
            informações pessoais incluem provedores que nos auxiliam em funções
            como:
          </Typography>

          <ul>
            <Typography element="li" variant="bodyS">
              Faturamento
            </Typography>
            <Typography element="li" variant="bodyS">
              Suporte a clientes e gerenciamento de clientes
            </Typography>
            <Typography element="li" variant="bodyS">
              Serviços de e-mail;
            </Typography>
            <Typography element="li" variant="bodyS">
              Hospedagem e armazenamento;
            </Typography>
            <Typography element="li" variant="bodyS">
              Analytics; e
            </Typography>
            <Typography element="li" variant="bodyS">
              Entrega de produtos ou brindes físicos (quando aplicável);
            </Typography>
          </ul>

          <Typography variant="bodyS">
            Em resumo: nós podemos compartilhar algumas informações suas com os
            nossos parceiros de negócio e provedores de serviços terceirizados,
            porém somente se for necessário para prestarmos os nossos serviços a
            você ou para atender aos interesses legítimos para o negócio do
            Ahazou.
          </Typography>

          <Typography element="h4" variant="displayS">
            (b) Seu compartilhamento de conteúdo
          </Typography>

          <Typography variant="bodyS">
            Toda informação ou conteúdo que você voluntariamente divulgue
            postando através do App, se torna disponível ao público dos meios
            que você escolher compartilhar.
          </Typography>

          <Typography variant="bodyS">
            Você pode compartilhar seus posts do Ahazou com suas contas de mídia
            social, como exemplo Facebook, Instagram e WhatsApp. Você deve se
            familiarizar com as políticas de privacidade de cada um desses
            serviços e com quaisquer configurações de privacidade que possam ser
            aplicadas aos designs que você escolher compartilhar nessas contas.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: todo conteúdo que você escolhe, salva e/ou edita no
            Ahazou é privado. Se você optar por tornar público os posts ou
            outras informações, tais informações poderão permanecer visíveis
            mesmo depois de removidas, através de caches em páginas ou se outros
            usuários tiverem feito cópias.
          </Typography>

          <Typography element="h4" variant="displayS">
            (c) Compartilhando com outros terceiros
          </Typography>

          <Typography variant="bodyS">
            O Ahazou também poderá compartilhar suas informações com terceiros
            em determinadas circunstâncias, tais como:
          </Typography>

          <ul>
            <Typography element="li" variant="bodyS">
              formos obrigados a fazê-lo como resultado de uma ordem judicial,
              intimação ou outro requisito legal;
            </Typography>
            <Typography element="li" variant="bodyS">
              quando acreditarmos que é necessário proteger você ou o Ahazou,
              investigando atividades criminosas ou potencialmente fraudulentas;
            </Typography>
            <Typography element="li" variant="bodyS">
              você consentiu em compartilhá-las com terceiros para uma
              finalidade específica;
            </Typography>
            <Typography element="li" variant="bodyS">
              você ou o administrador da sua conta optar por instalar
              aplicativos de terceiros que interagem com o nosso serviço. Por
              exemplo, você pode instalar um aplicativo terceiro de
              compartilhamento para armazenar, compartilhar e editar conteúdo
              por meio do Ahazou. Esses aplicativos terceiros não são
              controlados por nós e esta política de privacidade não cobre a
              maneira que eles usam as suas informações. Você deve revisar os{" "}
              <a href="/terms-of-use">termos</a> e condições de aplicativos
              terceiros antes de conectá-los ao Ahazou. Se você se opuser às
              informações sobre o compartilhamento com esses terceiros, não
              instale o aplicativo deles.
            </Typography>
            <Typography element="li" variant="bodyS">
              O Ahazou também compartilhará suas informações com terceiros nas
              circunstâncias em que vendermos, alienarmos ou transferirmos o
              Ahazou (incluindo quaisquer ações do Ahazou) ou qualquer
              combinação de seus produtos, serviços, ativos e/ou negócios a
              terceiros. Informações como nomes e endereços de e-mail do
              cliente, Conteúdo do usuário e outras informações do usuário
              relacionadas ao serviço podem estar entre os itens vendidos ou
              transferidos de outra forma nesses tipos de transações. Também
              venderemos, atribuiremos ou transferiremos essas informações no
              caso de desinvestimentos, fusões, aquisições, falências,
              dissoluções, reorganizações, liquidações, transações ou processos
              similares envolvendo todo ou parte do Ahazou. Você será notificado
              por e-mail e / ou um aviso em destaque no serviço se essa
              transação ocorrer e será notificado sobre quaisquer alterações
              materiais na maneira como lidamos com seus dados sob esta
              política.
            </Typography>
          </ul>

          <Typography element="h3" variant="displayM">
            4. Como nós transferimos, armazenamos e protegemos os seus dados
          </Typography>

          <Typography variant="bodyS">
            Suas informações coletadas através do Ahazou serão armazenadas e
            processadas no Brasil, Estados Unidos e em qualquer outro país em
            que o Ahazou ou suas subsidiárias, afiliadas ou provedores de
            serviços mantenham instalações. O Ahazou transfere as informações
            que coletamos sobre você, incluindo informações pessoais, para
            entidades afiliadas e outros terceiros além-fronteiras e do seu país
            ou jurisdição para outros países e em todo o mundo. Como resultado,
            podemos transferir informações, incluindo informações pessoais, para
            um país e jurisdição que não possua as mesmas leis de proteção de
            dados da sua jurisdição, e você consente com a transferência de
            informações para os EUA ou qualquer outro país no qual o Ahazou ou
            suas subsidiárias, afiliadas ou prestadores de serviços mantêm
            instalações e o uso e divulgação de informações sobre você, conforme
            descrito nesta Política de Privacidade.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: para executar nosso serviço, temos que usar computadores
            em todo o mundo. Isso significa que suas informações podem ser
            transferidas para os EUA e qualquer outro lugar em que o serviço
            seja operado.
          </Typography>

          <Typography element="h3" variant="displayM">
            5. Mantendo a sua informação em segurança
          </Typography>

          <Typography variant="bodyS">
            O Ahazou se preocupa com a segurança de suas informações e usa
            ferramentas apropriadas para preservar a integridade e a segurança
            de todas as informações coletadas através do serviço. Para proteger
            sua privacidade e segurança, tomamos medidas razoáveis (como
            solicitar uma senha exclusiva) para verificar sua identidade antes
            de conceder acesso à sua conta. Você é responsável por manter o
            sigilo de suas informações exclusivas de senha e conta e por
            controlar o acesso às suas comunicações por e-mail do Ahazou, o
            tempo todo. No entanto, o Ahazou não pode garantir a segurança de
            qualquer informação que você transmita ao Ahazou ou garantir que as
            informações no serviço não possam ser acessadas, divulgadas,
            alteradas ou destruídas. Suas configurações de privacidade também
            podem ser afetadas por alterações na funcionalidade de sites e
            serviços de terceiros que você adicionar ao serviço Ahazou, como
            redes sociais. O Ahazou não é responsável pela funcionalidade ou
            medidas de segurança de terceiros.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: nos preocupamos com a segurança dos seus dados e
            implementamos medidas para protegê-los, mas infelizmente não podemos
            garantir que nada de ruim aconteça com eles.
          </Typography>

          <Typography element="h3" variant="displayM">
            6. Compromisso com a informação
          </Typography>

          <Typography variant="bodyS">
            Caso alguma informação sob nosso controle seja comprometida como
            resultado de uma quebra de segurança, o Ahazou tomará medidas
            razoáveis para investigar a situação e, quando apropriado,
            notificará aqueles indivíduos cujas informações possam ter sido
            comprometidas e tomará outras medidas, de acordo com quaisquer leis
            e regulamentos aplicáveis.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: se houver uma violação de nossa segurança, nós a
            investigaremos e informaremos todos os usuários afetados.
          </Typography>

          <Typography element="h3" variant="displayM">
            7. Suas escolhas sobre suas informações
          </Typography>

          <Typography element="h4" variant="displayS">
            (a) Você controla suas informações de conta e configurações
          </Typography>

          <Typography variant="bodyS">
            Você pode atualizar as informações da sua conta fazendo login e
            alterando as configurações do seu perfil. Você também pode parar de
            receber nossas comunicações promocionais por e-mail clicando no
            "link de cancelamento de assinatura" fornecido nessas comunicações.
            Nos esforçamos para processar prontamente todas as solicitações de
            cancelamento de inscrição.
          </Typography>

          <Typography variant="bodyS">
            Você não pode optar por não receber comunicações relacionadas ao
            serviço (por exemplo, verificação da conta, confirmações e lembretes
            de compra e cobrança, alterações / atualizações dos recursos do
            serviço, avisos técnicos e de segurança), embora possa se opor a que
            forneça esses e-mails enviando um e-mail para{" "}
            <a href="mailto:meajuda@ahazou.com">meajuda@ahazou.com</a>.
          </Typography>

          <Typography variant="bodyS">
            Se você tiver alguma dúvida sobre a revisão ou modificação das
            informações da sua conta, entre em contato diretamente através do
            e-mail <a href="mailto:meajuda@ahazou.com">meajuda@ahazou.com</a>.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: você tem controle sobre as configurações da sua conta,
            como informações da conta e notificações por e-mail, mas há alguns
            comunicados importantes que sempre lhe enviaremos. Se você tiver
            alguma dúvida sobre a revisão ou modificação das informações da sua
            conta, entre em contato conosco diretamente em{" "}
            <a href="mailto:meajuda@ahazou.com">meajuda@ahazou.com</a>.
          </Typography>

          <Typography element="h4" variant="displayS">
            (b) Desativar a coleta das suas informações
          </Typography>

          <Typography variant="bodyS">
            Consulte as informações técnicas do seu dispositivo móvel para obter
            instruções sobre como excluir e desativar ferramentas de
            rastreamento / gravação. Dependendo do seu tipo de dispositivo,
            talvez não seja possível excluir ou desativar os mecanismos de
            rastreamento no seu dispositivo móvel.
          </Typography>

          <Typography variant="bodyS">
            Observe que a desativação destas ferramentas de rastreamento impede
            o Ahazou ou seus parceiros de negócios de rastrear as atividades do
            seu navegador em relação ao serviço e para uso em atividades de
            publicidade direcionada do Ahazou, inclusive através de sites de
            terceiros. No entanto, isso pode desativar muitos dos recursos
            disponíveis através do nosso serviço. Se você tiver alguma dúvida
            sobre a exclusão das ferramentas de rastreamento / gravação, entre
            em contato conosco diretamente em{" "}
            <a href="mailto:meajuda@ahazou.com">meajuda@ahazou.com</a>.
          </Typography>

          <Typography variant="bodyS">
            Se você deseja que paremos de coletar informações sobre você, você
            pode ajustar algumas configurações no seu dispositivo. Mas o Ahazou
            pode oferecer um serviço inferior e menos customizado sem esta
            coleta.
          </Typography>

          <Typography element="h4" variant="displayS">
            (c) Retenção de dados e exclusão de conta
          </Typography>

          <Typography variant="bodyS">
            Você pode solicitar a desativação e exclusão de sua conta enviando
            um e-mail para meajuda@ahazou.com. A exclusão será realizada em até
            1 dia útil. Quando excluímos sua conta, excluímos todos os dados
            vinculados à sua conta permanentemente sem chance de reativá-la no
            futuro.
          </Typography>

          <Typography element="h3" variant="displayM">
            8. Por quanto tempo mantemos as suas informações
          </Typography>

          <Typography variant="bodyS">
            Após o encerramento ou desativação da sua conta de usuário, o Ahazou
            reterá as informações do seu perfil e o Conteúdo do usuário por um
            tempo comercialmente razoável, e enquanto tivermos um objetivo
            válido para isso. Em particular, o Ahazou reterá suas informações
            com o objetivo de cumprir suas obrigações legais e de auditoria, e
            para fins de backup e arquivamento.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: retemos as informações do seu perfil e o conteúdo do
            usuário com a finalidade de fornecer nosso serviço a você e cumprir
            nossas obrigações legais e regulamentares.
          </Typography>

          <Typography element="h3" variant="displayM">
            9. Links para outros sites e serviços
          </Typography>

          <Typography variant="bodyS">
            Não nos responsabilizamos pelas práticas empregadas pelos sites ou
            serviços vinculados ao ou a partir do Ahazou, incluindo as
            informações ou o conteúdo nele contido. Lembre-se de que, quando
            você usa um link para ir do Ahazou para outro site, nossa Política
            de Privacidade não se aplica a sites ou serviços destes terceiros.
            Sua navegação e interação em qualquer site ou serviço de terceiros,
            incluindo aqueles que possuem um link ou anúncio em nosso site,
            estão sujeitas às próprias regras e políticas. Além disso, você
            concorda que não somos responsáveis e não exercemos controle sobre
            terceiros que você autoriza acessar o Conteúdo do usuário. Se você
            estiver usando um site ou serviço de terceiros (como o Facebook) e
            permitir esse acesso de terceiros ao seu Conteúdo do usuário, você o
            faz por seu próprio risco. Esta Política de Privacidade não se
            aplica a informações que coletamos por outros meios (inclusive
            off-line) ou de outras fontes que não sejam através do aplicativo.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: a Internet é composta por diversos links. Se publicarmos
            um link em um site de terceiros no Ahazou, não poderemos controlar o
            que acontece quando você acessa este link. O mesmo acontece se você
            deixar outro site usar seus dados no Ahazou; o uso de suas
            informações será regido pela Política de Privacidade destes
            terceiros.
          </Typography>

          <Typography element="h3" variant="displayM">
            10. Mudanças e atualizações desta política de privacidade
          </Typography>

          <Typography variant="bodyS">
            Nós podemos atualizar esta política periodicamente para refletir
            nossa prática atual e garantir a conformidade com as leis
            aplicáveis. Quando publicarmos alterações nesta política,
            revisaremos a data da "Última atualização" na parte superior desta
            política. Se fizermos alterações materiais na maneira como
            coletamos, usamos, armazenamos e / ou compartilhamos suas
            informações pessoais, notificaremos você publicando com destaque as
            alterações em nosso aplicativo e/ou enviando um e-mail para o
            endereço de e-mail associado à sua conta do Ahazou. Recomendamos que
            você verifique esta página periodicamente para se informar sobre
            quaisquer alterações.
          </Typography>

          <Typography variant="bodyS">
            Em resumo: não faremos grandes alterações em nossa Política de
            Privacidade sem avisar você, mas recomendamos visitar esta página de
            vez em quando para se atualizar.
          </Typography>

          <Typography element="h3" variant="displayM">
            11. Como entrar em contato conosco
          </Typography>

          <Typography variant="bodyS">
            Se você tiver alguma dúvida sobre esta Política de Privacidade ou
            sobre os nossos serviços, ou quiser fazer uma reclamação e/ou
            sugestão, entre em contato conosco em:
          </Typography>

          <ul>
            <Typography element="li" variant="bodyS">
              Email: <a href="mailto:meajuda@ahazou.com">meajuda@ahazou.com</a>
            </Typography>
            <Typography element="li" variant="bodyS">
              Escreva para: Ahazou, uma empresa de propriedade da Carreira
              Beauty Portal da Beleza Ltda, sociedade empresária limitada, com
              sede na Cidade de São Paulo, Estado de São Paulo, na Avenida
              Mofarrej, nº 154, 164-bloco 4, Vila Leopoldina, CEP 05311-000,
              inscrita no CNPJ/MF sob o no18.530.9400001-70.
            </Typography>
          </ul>

          <Typography variant="bodyS">
            Em resumo: sua privacidade é importante para nós e estamos felizes
            em responder qualquer dúvida que você possa ter.
          </Typography>
        </Container>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
